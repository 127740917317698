/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,600;1,100&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
}

