.fbox {
    position: relative;
    height: auto    ;
    width: 100%;
}

.fbox .group {
    height: auto;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
}

.fbox .overlap-group {
    background-color: #002858;
    height: auto; /* Adjust height for responsiveness */
    width: 100%;
    padding: 20px; /* Add padding for better spacing */
    box-sizing: border-box;
}

.fbox .element-dbraei-logo {
    height: 69px;
    object-fit: cover;
    position: relative;
    width: 300px;
    margin-bottom: 10px; /* Add margin for spacing */
}

.fbox .ICET-CODE-BRAB {
    color: var(--brand-colour-white);
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 120px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 124px;
}

.fbox .dr-BR-ambedkar {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 124px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 168px;
    width: 300px;
}

.fbox .text-wrapper {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 602px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 188px;
}

.fbox .div {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 989px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 188px;
}

.fbox .text-wrapper-2 {
    color: #ffffff;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 20px;
    font-weight: 600;
    left: 602px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 70px;
    white-space: nowrap;
}

.fbox .text-wrapper-3 {
    color: #ffffff;
    font-family: "Montserrat-SemiBold", Helvetica;
    font-size: 20px;
    font-weight: 600;
    left: 989px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 70px;
    white-space: nowrap;
}

.fbox .text-wrapper-4 {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 989px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 240px;
}

.fbox .text-wrapper-5 {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 602px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 136px;
}

.fbox .vision-mission {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 989px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 136px;
}

.fbox .text-wrapper-6 {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 602px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 214px;
}

.fbox .text-wrapper-7 {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 989px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 214px;
}

.fbox .text-wrapper-8 {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 602px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 110px;
}

.fbox .text-wrapper-9 {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 989px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 110px;
}

.fbox .text-wrapper-10 {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 602px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 162px;
}

.fbox .text-wrapper-11 {
    color: #ffffff;
    font-family: "Montserrat-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 989px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 162px;
}
.rotue_link {
    color: #fff !important;
    text-decoration: none;
  }
  .box-shadow_soft{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important; 
  }

  .footer_logo{
    width:200px;
    height:auto
  }