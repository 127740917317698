#HsStyle .image-container {
  flex: 0 0 auto;
}

.image-container .item-image {
  max-width: 95%;
  height: auto;
  border-radius: 16px;
}

#HsStyle .description {
  flex: 1;
}

#HsStyle .item {
  display: flex;
  margin-bottom: 10px;
}

#HsStyle .container {
  display: flex;
  flex-direction: column;
  position: relative;
  right: 70px;
}

#HsStyle p {
  width: 200%; /* Adjusted width for better responsiveness */
}

@media screen and (max-width: 900px) {
  #HsStyle p {
    display: flex;
    width: 100%;
    text-align: justify;
    flex-direction: column;
  }
  #HsStyle .item-image {
    max-width: 100%;
    height: auto;
  }
  #HsStyle h2 {
    max-width: 100%;
    height: auto;
  }
  #HsStyle .description {
    flex: 1;
    margin-left: 0; /* Removed unnecessary margin */
  }
  #HsStyle .container {
    display: flex;
    flex-direction: column;
    position: relative;
    right: 70px;
  }
}

@media screen and (max-width: 768px) {
  #HsStyle p {
    display: flex;
    width: 90%;
    text-align: justify;
    flex-direction: column;
  }
  #HsStyle .item-image {
    max-width: 100%;
    height: auto;
  }
  #HsStyle h2 {
    max-width: 100%;
    height: auto;
  }
  .description {
    flex: 1; /* Adjusted flex value */
    margin-left: 0; /* Removed unnecessary margin */
  }
}
