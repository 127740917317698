.course_offering_heading{
    background-color: #192F59;
    color: #fff;
    width: 250px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}
.course_offering_list{
    list-style-type: none;
    padding: 0;
}
.course_offering_list li {
    background-color: #d9d9d9;
    font-size: 20px;
    font-weight: 600;
}

.center-grid {
    display: flex;
    justify-content: center;
  }