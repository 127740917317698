.Chbox {
  height: 569px;
  width: 100%;
}

.Chbox .rectangle {
  height: 569px;
  /* left: 0; */
  position: relative;
  /* top: 0; */
  width: 100%;
}
.slick-center h3{
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}
.center .slick-center {
  opacity: 1;
  /* border: 1px solid saddlebrown; */
  transform: scale(1.08);
  box-shadow: 0px 0px 10px 0px #00000040;
}
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.Header_Img_gradinent {
  position: absolute;
  z-index: 100;
  top: 0%;
  height: 560px;
  width: 100%;
  background-image: linear-gradient(
    to right,
    #1a305a 20%,
    #1a305a00,
    transparent
  );
}
.Header_Img_Heading {
  width: 500px;
  z-index: 110;
  position: absolute;
  top: 35%;
  left: 8%;
  color: white;
}
.Header_Img_Heading h1 {
  text-align: left;
}
.Header_Img_Btn {
  background-color: #f15215 !important;
  color: #fff;
  font-size: 1rem !important;
  margin: 1rem;
  border-radius: 10px !important;
  text-transform: none !important;
}
.Header_Scrol_text {
  background-color: #192f59;
  color: #fff;
  text-align: end;
}
.Carosel_shading {
  background-image: linear-gradient(
    to right,
    #ee95e3,
    transparent,
    transparent,
    transparent,
    transparent,
    transparent,
    #e595fd
  );
}
.Image_carosel .slick-slider .slick-dots {
  bottom: 15px !important;
}
.Image_carosel .slick-slider .slick-dots li button:before {
  color: #fff !important;
  background: rgba(6, 176, 228, 0.185);
}

.whStyle .slick-prev,.whStyle .slick-next{
  z-index: 1;
}
.whStyle .slick-prev::before, .whStyle .slick-next::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 35px;
  background: #fff;
  opacity: 1;
  border-radius: 50px;
  width: 45px;
  color:black;
  height: 45px;
  line-height: 47px;
  display: block;
  box-shadow: 0 7px 10px rgba(0,0,0,0.2), 0 6px 6px rgba(0,0,0,0.23);
}

.whStyle .slick-next::before {
  font-family: "Font Awesome 5 Free"; 
  font-weight: 700; 
  content: "\f105";
  color:#222f41c9;
  z-index: 0;
  right: 25px;
  position: relative;
}

.whStyle .slick-prev::before{
  font-family: "Font Awesome 5 Free"; 
      font-weight: 700; 
      content: "\f104";
      color:#222f41c9;
      left: 5px;
      position: relative;
}

.slick-dots {
  position: absolute;
  bottom: 59px !important;
  display: block;
  height: 20px;
  width: 100%;
  color: "white";
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
}