.placement_container {
  width: 300% !important;
  height: auto !important;
  display: flex;
  flex-direction: row; /* add this */
  justify-content: center;
  align-items: center;
}

.placement_grid {
  background-color: #f15215;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-left: 10px;
  padding: 60px;
}

.product-list {
  display: flex;
  overflow: hidden;
  animation: scroll 20s linear infinite;
}

.Brand_Container {
  background-color: #d9d9d9;
  height: 200px;
  width: 100%;
}

@keyframes scroll {
  0% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(-10%);
  }
}

/* CompanyList.css */

.CompanyListSection {
  padding: 30px;
  background-color: #d9d9d9;
  height: 250px;
}

.CompanyListTitle {
  font-size: 1.5rem;
  padding-bottom: 20px;
}

.CompanyListSlider {
  max-width: 100%;
  overflow: hidden;
}

.CompanyListItem {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
}

.CompanyListLogo {
  max-width: 100%;
  height: auto;
}


/* PlacementProcess.css */

.process-grid-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.process-grid-section div {
  margin: 27px;
  padding-top: 19px;
  text-align: center;
}

.process-grid-section h1 {
  font-size: 1.5rem;
}

.process-grid-section p {
  font-size: 0.9rem;
}

.Process_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.process_grid {
  flex: 1;
  margin: 20px;
  text-align: center;
}

.process_grid img {
  height: 32px;
  width: 33px;
}

.process_grid h4 {
  font-size: 15px;
  font-weight: 600;
}

.process_grid p {
  font-size: 15px;
  font-weight: 100;
}

/* Responsive Styles */

@media screen and (max-width: 768px) {
  .process-grid-section div {
    margin: 10px;
    padding-top: 10px;
  }

  .process-grid-section h1 {
    font-size: 1.2rem;
  }

  .Process_container {
    margin-top: 10px;
  }

  .process_grid {
    flex-basis: 100%;
    margin: 10px;
  }
}


.Training_process {
  display: flex;
  flex-direction: column;
}

.Training_img {
  width: 50%;
  height: 50%;
  display: block;
  margin: 0 auto;
}
