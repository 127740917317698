.studyWithUsStyles {
  background: linear-gradient(
    90deg,
    rgba(30, 115, 190, 1) 27%,
    rgba(241, 82, 21, 1) 85%
  );
  display: flex;
  position: relative;
  bottom: 10px;
  padding: 20px; /* Add padding for better spacing on small screens */
}

.description {
  text-align: justify;
  font-size: 22px;
}
.notice-container {
  flex: 1;
  margin: 0px;
  height: "auto";
  display: flex;
  flex-direction: column;
}

/* Media query for screens smaller than 768px (adjust as needed) */
@media (max-width: 768px) {
  .studyWithUsStyles {
    flex-direction: column; /* Stack elements vertically on small screens */
    align-items: center; /* Center the items */
  }

  .studyDescription {
    height: auto; /* Allow the description to expand based on content */
    overflow: visible; /* Show the entire description on small screens */
    width: 75vw; /* Adjust width as needed */
  }
}

@media (max-width: 600px) {
  .studyWithUsStyles {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .studyDes {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .description {
    height: auto; /* Allow the description to expand based on content */
    overflow: visible; /* Show the entire description on smaller screens */
    width: 90vw; /* Adjust width as needed */
  }
}
