.radio-cell {
    position: relative;
  }
  
  .answer-divider {
    position: absolute;
    height: 100%;
    width: 1px;
    right: 0;
    top: 0;
    bottom: 0;
    border: 1 solid rgb(0, 0, 0);
    margin: auto;
  }

  .feedback-container {
    display: flex;
    margin-top: 2rem;
  }

  .feedBack_container {
    display: flex;
    flex-direction: row;

  }
  .submission-message{
   
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .submission-message img {
    /* margin:auto; */
    justify-content:center;
    display: flex;
    margin: 0px;

    
  }

  .submission-message Button{
    background-color:  #63769A;
    font-weight: 600;
    color: white
  } 

  .submission-message Button:hover{
    background-color:  #808080;
    color: white;
  }


@media screen and (max-width: 768px) {
  .feedBack_container{
    display: flex;
    flex-direction: column;
    color:rgb(85, 106, 193)
  }
}
