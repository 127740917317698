.active-accordion{
    background-color: gray;
    color: white;
}

.selected{
    background-color: rgba(132, 134, 173, 0.268);
}

.AccordionSummary:hover{
    background-color: #d9e5fc;
}

.Inactive-accordion{
    background-color: linear-gradient(90deg, rgba(30,115,190,1) 27%, rgba(222,78,23,1) 85%);
}

.selected-accordion {
    background-color: black;
    color: rgb(247, 15, 15);
  }
  
  .selected-text {
    color: rgb(219, 21, 21);
  }